import React, { useEffect, useState } from "react";
import "../../assets/css/style.css";
import Pagination from "../../redux/customHooks/pagination";
import {
  Pagination as PaginationConstants,
  DateTimeFormats,
  appointmentTypes,
  PATIENT_PORTAL_AUTO_LOGIN_API_KEY,
  ConsultationStatus
} from "../../constants";
import {
  formatDateTime,
  toTimezoneName,
  formatDateTimeWithZone,
  toLocal,
  isEqualToCurrentDate,
  getTimeSince,
  isImageFile,
  isValidDocFileForPatientUpload,
  showAlertDialouge,
  getNameInitials,
  onImageError,
  checkBrowserVideoCallEligibility
} from "../../helpers/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  hideLoadingSpinner,
  showLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { Link, useNavigate } from "react-router-dom";
import {
  getAssociatedOnDemandCampaigns_Ajax,
  getCampaign_Ajax,
  updateOnDemandConsultation_Ajax
} from "../../helpers/requests";
import { handleApiErrors, openUrlInNewTab } from "../../helpers/utils";
import NoPreviewAvailable from "./../../assets/images/No_preview_available.jpg";
import {
  getAutoLoginUrl,
  getSelectedPatientId,
  getTokenData
} from "../../helpers/authManager";
import UnsupportedBrowserModal from "../UnsupportedBrowserModal/UnsupportedBrowserModal";
import ReactTooltip from "react-tooltip";

const VisitHistoryTable = (props) => {
  const dispatch = useDispatch();
  const [browserEligibility, setBrowserEligibility] = useState(null);
  const [autoLoginUrl, setAutoLoginUrl] = useState("");

  const {
    patientOnDemandConsultations,
    totalOnDemandConsultationsCount,
    getOnDemandConsultations,

    followUpConsultations,
    totalFollowUpConsultationsCount,
    getFollowUpConsultations,

    utcOffset,
    isDST,
    currentPatient,

    reloadVisitHistory,
    setReloadVisitHistory
  } = props;

  const [
    onDemandConsultationsCurrentPage,
    setOnDemandConsultationsCurrentPage
  ] = useState(PaginationConstants.FirstPageNo);
  const [
    followUpConsultationsCurrentPage,
    setFollowUpConsultationsCurrentPage
  ] = useState(PaginationConstants.FirstPageNo);

  useEffect(() => {
    let browserEligibility = checkBrowserVideoCallEligibility();
    setBrowserEligibility(browserEligibility);
  }, []);

  useEffect(() => {
    getOnDemandConsultations(
      onDemandConsultationsCurrentPage,
      PaginationConstants.ItemPerPage
    );
  }, [onDemandConsultationsCurrentPage]);

  useEffect(() => {
    getFollowUpConsultations(
      followUpConsultationsCurrentPage,
      PaginationConstants.ItemPerPage
    );
  }, [followUpConsultationsCurrentPage]);

  useEffect(() => {
    if (reloadVisitHistory) {
      getOnDemandConsultations(
        onDemandConsultationsCurrentPage,
        PaginationConstants.ItemPerPage
      );
      getFollowUpConsultations(
        followUpConsultationsCurrentPage,
        PaginationConstants.ItemPerPage
      );
      setReloadVisitHistory(false);
    }
  }, [reloadVisitHistory]);

  function getOnDemandConsultationTimeAndDate(consultation) {
    if (consultation && consultation.createdAt) {
      var creationTime = "";

      if (utcOffset && isDST) {
        creationTime =
          formatDateTime(consultation.createdAt, DateTimeFormats.HH_MM_A) +
          " (" +
          toTimezoneName(utcOffset, isDST) +
          ")";
      } else {
        creationTime = formatDateTimeWithZone(
          toLocal(consultation.createdAt),
          DateTimeFormats.HH_MM_A
        );
      }

      var creationDateOrTimeSince = "";

      if (utcOffset) {
        creationDateOrTimeSince = formatDateTime(
          consultation.createdAt,
          DateTimeFormats.MMM_DD_YYYY
        );
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(consultation.createdAt),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationTime && creationDateOrTimeSince) {
        return (
          <>
            <p className="fs-4 mb-0">{creationTime}</p>
            <span className="d-block">{creationDateOrTimeSince}</span>
          </>
        );
      }
    }
  }

  function getProviderJoinedTimeAndDate(consultation) {
    if (consultation && consultation.providerJoinedAt) {
      var creationTime = "";

      if (utcOffset && isDST) {
        creationTime =
          formatDateTime(
            consultation.providerJoinedAt,
            DateTimeFormats.HH_MM_A
          ) +
          " (" +
          toTimezoneName(utcOffset, isDST) +
          ")";
      } else {
        creationTime = formatDateTimeWithZone(
          toLocal(consultation.providerJoinedAt),
          DateTimeFormats.HH_MM_A
        );
      }

      var creationDateOrTimeSince = "";

      if (utcOffset) {
        creationDateOrTimeSince = formatDateTime(
          consultation.providerJoinedAt,
          DateTimeFormats.MMM_DD_YYYY
        );
      } else {
        creationDateOrTimeSince = formatDateTime(
          toLocal(consultation.providerJoinedAt),
          DateTimeFormats.MMM_DD_YYYY
        );
      }

      if (creationTime && creationDateOrTimeSince) {
        return (
          <>
            <p className="fs-4 mb-0">{creationTime}</p>
            <span className="d-block">{creationDateOrTimeSince}</span>
          </>
        );
      }
    }
  }

  function getIsPatientReferredVerbiage(referralCount) {
    return referralCount > 0 ? "Yes" : "No";
  }

  function isVisitHistoryDataAvailable() {
    return (
      (patientOnDemandConsultations &&
        Array.isArray(patientOnDemandConsultations.consultations) &&
        patientOnDemandConsultations.consultations.length > 0) ||
      (followUpConsultations &&
        Array.isArray(followUpConsultations) &&
        followUpConsultations.length > 0)
    );
  }

  function getOnDemandConsultationsTable(patientOnDemandConsultations) {
    if (
      patientOnDemandConsultations &&
      Array.isArray(patientOnDemandConsultations.consultations) &&
      patientOnDemandConsultations.consultations.length > 0
    ) {
      return (
        <>
          <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
            <h1>Completed Calls</h1>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Patient</th>
                <th>Provider</th>
                <th>Creation Time</th>
                <th>State </th>
                <th>Campaign</th>
                <th>Provider Visited At</th>
                <th>Referrals</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {patientOnDemandConsultations.consultations.map(
                (consultation, index) => {
                  return (
                    <tr key={index.toString()}>
                      <td data-label="Patient">
                        <div className="d-inline-flex align-items-center">
                          {currentPatient?.photoId ? (
                            <img
                              className="rounded-circle gth-pro-img me-2"
                              src={currentPatient?.photoId}
                              onError={onImageError}
                              alt=""
                            />
                          ) : (
                            <div className="visit-history-profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                              {getNameInitials(consultation.patientName)}
                            </div>
                          )}
                          {consultation.patientName}
                        </div>
                      </td>
                      <td data-label="Provider">
                        {" "}
                        {consultation.providerName}
                      </td>
                      <td data-label="Creation Time">
                        {getOnDemandConsultationTimeAndDate(consultation)}
                      </td>
                      <td data-label="State">
                        {" "}
                        {consultation.campaignLocationStateName}{" "}
                      </td>
                      <td data-label="Campaign">{consultation.campaignName}</td>
                      <td data-label="Provider Visited At">
                        {getProviderJoinedTimeAndDate(consultation)}
                      </td>
                      <td data-label="Referrals">
                        {getIsPatientReferredVerbiage(
                          consultation.postCallReferralSuggestionCount
                        )}
                        {consultation.postCallReferralSuggestionCount > 0 && (
                          <a href="/referrals" className="ms-1">
                            <i
                              className="bi bi-info-circle-fill fs-3 text-primary ms-2"
                              aria-hidden="true"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              data-tip="Your provider has recommended a referral."
                              aria-label="Your provider has recommended a referral."
                            ></i>
                            <ReactTooltip
                              className="tooltip"
                              backgroundColor="white"
                              textColor="black"
                            />
                          </a>
                        )}
                      </td>
                      <td data-label="Action">
                        <a
                          href={`/visit-history-print/${currentPatient.patid}/${consultation.providerJoinedAt}/${consultation.id}`}
                          target="_blank"
                          className="btn btn-primary btn-xs text-nowrap mt-2 mt-md-0"
                        >
                          <i className="bi bi-printer-fill"></i> Print
                        </a>
                      </td>
                    </tr>
                  );
                }
              )}
            </tbody>
          </table>
          <Pagination
            className="pagination-bar"
            currentPage={onDemandConsultationsCurrentPage}
            totalCount={
              totalOnDemandConsultationsCount
                ? totalOnDemandConsultationsCount
                : 0
            }
            pageSize={PaginationConstants.ItemPerPage}
            onPageChange={(page) => setOnDemandConsultationsCurrentPage(page)}
          />
        </>
      );
    }
  }

  function redirectToConferenceUrl(meetingJoinUrl) {
    var err = "";

    if (meetingJoinUrl) {
      var tokenData = getTokenData();

      if (tokenData && tokenData.token) {
        meetingJoinUrl += "&apikey=" + PATIENT_PORTAL_AUTO_LOGIN_API_KEY;
        meetingJoinUrl += "&token=" + tokenData.token;
        window.location.href = meetingJoinUrl;
      } else {
        err = "Token data not found.";
      }
    } else {
      err = "Meeting join Url not found.";
    }

    if (err) {
      showAlertDialouge("Error", err);
    }
  }

  function showUnsupportedBrowserModal(autoLoginUrl) {
    if (autoLoginUrl) {
      setAutoLoginUrl(autoLoginUrl);
      window.$("#modal-unsupported-browser").modal("show");
    }
  }

  function joinVideoCallBtnClicked(consultation) {
    let eligibilty = checkBrowserVideoCallEligibility();
    if (!eligibilty.status) {
      let selectedPatientId = getSelectedPatientId();
      let autoLoginUrl = getAutoLoginUrl({
        redirectTo: "/visit-history",
        patientId: selectedPatientId
      });
      showUnsupportedBrowserModal(autoLoginUrl);
      return;
    }

    if (consultation && consultation.id && consultation.meetingJoinUrl) {
      var data = {
        id: consultation.id,
        status: ConsultationStatus.Initiated
      };

      dispatch(showLoadingSpinner());
      updateOnDemandConsultation_Ajax(
        JSON.stringify(data),
        function (response) {
          dispatch(hideLoadingSpinner());

          if (response && response.success) {
            getFollowUpConsultations(
              followUpConsultationsCurrentPage,
              PaginationConstants.ItemPerPage
            );
            redirectToConferenceUrl(consultation.meetingJoinUrl);
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
          handleApiErrors(err);
        }
      );
    }
  }

  function renderFollowUpConsultationTable() {
    if (
      followUpConsultations &&
      Array.isArray(followUpConsultations) &&
      followUpConsultations.length > 0
    ) {
      return (
        <>
          <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
            <h1>Follow Up</h1>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Patient</th>
                <th>Provider</th>
                <th>Creation Time</th>
                <th>State </th>
                <th>Campaign</th>
                <th>Follow Up</th>
              </tr>
            </thead>
            <tbody>
              {followUpConsultations.map((consultation, index) => {
                return (
                  <tr key={index.toString()}>
                    <td data-label="Patient">
                      <div className="d-inline-flex align-items-center">
                        {currentPatient?.photoId ? (
                          <img
                            className="rounded-circle gth-pro-img me-2"
                            src={currentPatient?.photoId}
                            onError={onImageError}
                            alt=""
                          />
                        ) : (
                          <div className="visit-history-profile-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
                            {getNameInitials(consultation.patientName)}
                          </div>
                        )}
                        {consultation.patientName}
                      </div>
                    </td>
                    <td data-label="Provider"> {consultation.providerName}</td>
                    <td data-label="Creation Time">
                      {getOnDemandConsultationTimeAndDate(consultation)}
                    </td>
                    <td data-label="State">
                      {" "}
                      {consultation.campaignLocationStateName}{" "}
                    </td>
                    <td data-label="Campaign">{consultation.campaignName}</td>
                    <td data-label="Provider Visited At">
                      <a
                        onClick={() => joinVideoCallBtnClicked(consultation)}
                        href="javascript:void(0)"
                      >
                        Click here to join
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            className="pagination-bar"
            currentPage={followUpConsultationsCurrentPage}
            totalCount={
              totalFollowUpConsultationsCount
                ? totalFollowUpConsultationsCount
                : 0
            }
            pageSize={PaginationConstants.ItemPerPage}
            onPageChange={(page) => setFollowUpConsultationsCurrentPage(page)}
          />
        </>
      );
    }
  }

  function showVisitHistoryPageData() {
    if (isVisitHistoryDataAvailable()) {
      return (
        <>
          {renderFollowUpConsultationTable()}
          {getOnDemandConsultationsTable(patientOnDemandConsultations)}
        </>
      );
    } else {
      return (
        <div className="row">
          <div className="col-sm-12">
            <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
              <div className="d-flex justify-content-center mt-5 mb-5">
                <h2>No visit history data found</h2>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  return (
    <>
      <section className="step-section py-4 py-md-5">
        <div className="container">
          <div className="row mb-3">
            <div className="col-sm-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard">Home</Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page">
                    Visit History
                  </li>
                </ol>
              </nav>
            </div>
          </div>
          <div className="gth-main-header d-flex justify-content-between align-items-center mb-3">
            <h1>Visit History</h1>
          </div>
          <div className="row">
            <div className="col-sm-12">
              <div className="bg-white border-radius-xlg px-4 px-md-5 py-4">
                {showVisitHistoryPageData()}
              </div>
            </div>
          </div>
        </div>

        <UnsupportedBrowserModal
          message={browserEligibility?.message}
          Url={autoLoginUrl}
        />
      </section>
    </>
  );
};

export default VisitHistoryTable;
