import React from "react";
import { getNameInitials, onImageError } from "../../helpers/utils";

const SentMessage = ({ message, userImg, senderName }) => {
  return (
    <>
      <div className="chatbox send-msg">
        <div className="chat-msg user-input">{message}</div>
        {userImg ? (
          <img
            src={userImg}
            onError={onImageError}
            className="auther-img rounded-circle"
          />
        ) : (
          <>
            <div className="chat-sender-initial me-2 rounded-circle d-flex justify-content-center align-items-center">
              {getNameInitials(senderName)}
            </div>
          </>
        )}
        <br />
      </div>
    </>
  );
};

export default SentMessage;
