import React, { useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useAlert } from "react-alert";

import {
  SignUpFormFields,
  Roles,
  SELF_SUBSCRIBER_RELATION_CODE
} from "../../constants";
import { registerUser_Ajax } from "../../helpers/requests";
import {
  isPasswordValid,
  showAlertDialouge,
  validatePassword,
  extractCustomLinkQueryParams,
  executeDentistDirectReferralProcess,
  getUtmParams
} from "../../helpers/utils";
import {
  showLoadingSpinner,
  hideLoadingSpinner
} from "../../redux/actions/loadingSpinner";
import { storeTokenData } from "../../helpers/authManager";
import { savePgId, saveTokenData } from "../../redux/actions/userData";
import { getPatientOrigin, getPgidFromUrl } from "../../ApplicationSettings";

const SetPasswordForm = ({
  formData,
  insuranceData,
  isSignUpExternalPatient,
  consentFormData
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const paramsWithoutDecode = extractCustomLinkQueryParams();

  const navigateBack = () => {
    if (location.state?.from) {
      navigate(
        { pathname: location.state.from, search: `?${searchParams}` },
        {
          state: {
            patientData: formData,
            insurancePrefillData: insuranceData,
            isSignUpExternalPatient: isSignUpExternalPatient,
            backFromNext: true,
            consentFormData: consentFormData
          }
        }
      );
    } else {
      navigate(-1);
    }
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid }
  } = useForm({ mode: "onChange" });
  const officeId = useSelector(
    (state) => state?.appSettings?.settingsData?.officeId
  );

  function passwordConfirmed() {
    var password = getValues(SignUpFormFields.Password);
    var confirmPassword = getValues(SignUpFormFields.ConfirmPassword);

    if (!isPasswordValid(password)) {
      showAlertDialouge("Error", "Please enter a valid password");
    } else if (password != confirmPassword) {
      showAlertDialouge("Error", "Passwords do not match");
    } else {
      return true;
    }
  }

  function includeAdditionalData(formData) {
    const utmParams = getUtmParams();

    if (utmParams) {
      formData.utmParams = {};
      Object.keys(utmParams).forEach((key) => {
        if (utmParams[key]) {
          formData.utmParams[key] = utmParams[key];
        }
      });
    }
  }

  function registerUser(passwordForm) {
    var pgId = getPgidFromUrl();

    if (
      passwordConfirmed() &&
      pgId &&
      formData &&
      passwordForm?.password &&
      officeId >= 0
    ) {
      formData.password = passwordForm.password;
      formData.confirmPassword = passwordForm.confirmPassword;
      formData.roleId = Roles.Patient;
      formData.pgId = pgId;
      formData.origin = getPatientOrigin();
      formData.patientInsurance = insuranceData;
      formData.SubmittedForms = consentFormData;

      const isSelfSubscriber =
        insuranceData?.relationToPatient == SELF_SUBSCRIBER_RELATION_CODE;
      const isSubscriberStateSameWithPatientState =
        formData?.state == insuranceData?.state;
      if (isSelfSubscriber && isSubscriberStateSameWithPatientState) {
        formData.address1 = insuranceData?.insuredAddress;
        formData.city = insuranceData?.insuredCity;
        formData.zip = insuranceData?.zip;
        formData.gender = insuranceData?.gender;
      }

      includeAdditionalData(formData);
      dispatch(showLoadingSpinner());
      registerUser_Ajax(
        JSON.stringify(formData),
        officeId,
        async function (response) {
          dispatch(hideLoadingSpinner());
          if (response?.success && response.data && response.data.authData) {
            let authData = response.data.authData;

            if (authData.token && authData.info && authData.info.pgid) {
              storeTokenData(authData.info.pgid, JSON.stringify(authData));
              dispatch(savePgId(authData.info.pgid));
            } else if (authData.token && authData.user && authData.user.pgid) {
              storeTokenData(authData.user.pgid, JSON.stringify(authData));
              dispatch(savePgId(authData.user.pgid));
            }

            dispatch(saveTokenData(authData));
            await executeDentistDirectReferralProcess(
              paramsWithoutDecode.linkType,
              paramsWithoutDecode.dataString,
              response.data.authData.info?.patid
            );
            navigate("/dashboard");
          } else if (response?.success) {
            showAlertDialouge("Message", response.message, function () {
              navigate("/login");
            });
          } else if (response && !response.success && response.message) {
            showAlertDialouge("Error", response.message);
          }
        },
        function (err) {
          dispatch(hideLoadingSpinner());
        }
      );
    }
  }

  function toogleDisplayPassword() {
    setShowPassword(!showPassword);
  }

  function toogleDisplayConfirmPassword() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <div className="progress mb-3">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: "100%" }}
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>

          <form
            className="bg-white border-radius-xlg px-4 px-md-5 py-4"
            onSubmit={handleSubmit(registerUser)}
          >
            <div className="row">
              <div className="col-md-6 offset-md-3">
                <h2 className="text-center text-violet mb-4">
                  Set Your Password
                </h2>
                <div className="text-secondary fw-bold fs-5 mb-1">
                  * All fields are required
                </div>
                <div className="form-group mb-3">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <label className="form-label">Password</label>
                    </div>
                    <div className="col">
                      <div className="fs-5 mb-1 show-password">
                        <a
                          onClick={() => {
                            toogleDisplayPassword();
                          }}
                          className="ms-auto d-table text-muted"
                        >
                          {showPassword ? "Hide Password" : "Show Password"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <input
                    type={!showPassword ? "password" : "text"}
                    className="form-control"
                    placeholder="Password"
                    {...register(SignUpFormFields.Password, {
                      required: true,
                      validate: (value) => validatePassword(value)
                    })}
                  />
                  {!errors.password && (
                    <span className="fs-6 text-muted mt-1 d-inline-block align-top w-100">
                      Enter a strong password with at least 8 characters long
                      and includes at least one uppercase letter, one lowercase
                      letter, one digit and one special character.
                    </span>
                  )}
                  {errors.password && (
                    <span className="help-block">
                      Please enter a valid password. Your password must contain
                      at least 8 characters long and includes at least one
                      uppercase letter, one lowercase letter, one digit and one
                      special character.
                    </span>
                  )}
                </div>
                <div className="form-group mb-3">
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <label className="form-label">Confirm Password</label>
                    </div>
                    <div className="col">
                      <div className="fs-5 mb-1 show-password">
                        <a
                          onClick={() => {
                            toogleDisplayConfirmPassword();
                          }}
                          className="ms-auto d-table text-muted"
                        >
                          {showConfirmPassword
                            ? "Hide Password"
                            : "Show Password"}
                        </a>
                      </div>
                    </div>
                  </div>
                  <input
                    type={!showConfirmPassword ? "password" : "text"}
                    className="form-control"
                    placeholder="Confirm Password"
                    {...register(SignUpFormFields.ConfirmPassword, {
                      required: true,
                      validate: (value) =>
                        getValues(SignUpFormFields.Password) === value
                    })}
                  />
                  {errors.confirmPassword && (
                    <span className="help-block">
                      The password and confirmation password do not match
                    </span>
                  )}
                </div>
                <div className="form-group mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="agreeTerms"
                    {...register(SignUpFormFields.Consent, { required: true })}
                  />
                  <label
                    className="form-check-label fs-6 text-muted mt-1 d-inline-block align-top w-100"
                    htmlFor="agreeTerms"
                  >
                    By clicking "Create Account" you agree that you have read
                    and consent to the{" "}
                    <a
                      className="text-muted"
                      href="https://dental.com/terms-and-conditions-of-use"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>{" "}
                    &nbsp; of Use and to the &nbsp;{" "}
                    <a
                      className="text-muted"
                      href="https://dental.com/privacy-practices"
                      target="_blank"
                    >
                      Privacy Practices
                    </a>
                    .
                  </label>
                </div>
                <div
                  className="nav form-group text-center d-flex flex-wrap justify-content-center mt-2 mt-md-4 col-sm-12 button-wrapper"
                  id="step-tab"
                  role="tablist"
                >
                  <div className="form-group text-center">
                    <button
                      className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                      onClick={navigateBack}
                    >
                      Back
                    </button>
                  </div>
                  <div className="form-group text-center">
                    <button
                      type="submit"
                      className="btn btn-secondary btn-rounded btn-lg px-3 px-md-5 mx-md-2 mt-3 mt-md-0"
                      disabled={!isValid}
                    >
                      Create Account
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SetPasswordForm;
