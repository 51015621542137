import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import Login from "./pages/Login/Login";
import About from "./components/About/About";
import Referrals from "./pages/Referrals/Referrals";
import Education from "./pages/Education/Education";
import Forms from "./pages/Forms/Forms";
import FormPrint from "./pages/FormPrint/FormPrint";
import Uploads from "./pages/Uploads/Uploads";
import Notes from "./pages/Notes/Notes";
import VisitHistory from "./pages/VisitHistory/VisitHistory";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";

import ProfileSetting from "./pages/ProfileSetting/ProfileSetting";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import BillingInformation from "./pages/BillingInformation/BillingInformation";
import MyDependents from "./pages/MyDependents/MyDependents.js";
import SignUp from "./pages/SignUp/SignUp";
import RegistrationSuccess from "./pages/RegistrationSuccess/RegistrationSuccess.js";
import RegistrationConsentForm from "./pages/RegistrationConsentForm/RegistrationConsentForm.js";
import SetPassword from "./pages/SetPassword/SetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Prescription from "./pages/Prescription/Prescription";
import SeeDentist from "./pages/SeeDentist/SeeDentist";
import SeeDentistBilling from "./pages/SeeDentist/SeeDentistBilling";
import SeeDentistReview from "./pages/SeeDentist/SeeDentistReview";
import Layout from "./pages/Layout/Layout";
import RequireAuth from "./helpers/RequireAuth";
import AppointmentSchedule from "./pages/AppointmentSchedule/AppointmentSchedule";
import AppointmentScheduleBilling from "./pages/AppointmentSchedule/AppointmentScheduleBilling";
import AppointmentScheduleDateTimePage from "./pages/AppointmentSchedule/AppointmentScheduleDateTimePage";
import AppointmentScheduleReview from "./pages/AppointmentSchedule/AppointmentScheduleReview";
import AppointmentReschedule from "./pages/AppointmentReschedule/AppointmentReschedule";
import AppointmentCancel from "./pages/AppointmentCancel/AppointmentCancel";
import PrescriptionPrint from "./components/Print/PrescriptionPrint";
import NotesPrint from "./components/Print/NotesPrint";
import VisitHistoryPrint from "./components/Print/VisitHistoryPrint";
import FormSubmission from "./pages/FormSubmission/FormSubmission";
import SeeDentistPharmacySelection from "./pages/SeeDentist/SeeDentistPharmacySelection";
import AppointmentSchedulePharmacySelection from "./pages/AppointmentSchedule/AppointmentSchedulePharmacySelection";
import SurveyFormSubmission from "./pages/SurveyFormSubmission/SurveyFormSubmission";

import Instruction from "./pages/Instruction/Instruction";
import Questionnaire from "./pages/Questionnaire/Questionnaire";
import QuestionnaireRecordRequest from "./pages/QuestionnaireRecordRequest/QuestionnaireRecordRequest";
import QuestionnaireIntakeForm from "./pages/QuestionnaireIntakeForm/QuestionnaireIntakeForm";
import QuestionnaireReviewSubmit from "./pages/QuestionnaireReviewSubmit/QuestionnaireReviewSubmit";
import QuestionnaireBillingInfo from "./pages/QuestionnaireBillingInfo/QuestionnaireBillingInfo";
import QuestionnaireAfterPaymentSuccessful from "./pages/QuestionnaireAfterPaymentSuccessful/QuestionnaireAfterPaymentSuccessful";
import ConsentForm from "./pages/ConsentForm/ConsentForm";
import CaseReview from "./pages/CaseReview/CaseReview";
import RequestCompletedDetail from "./pages/RequestCompletedDetail/RequestCompletedDetail";
import QuestionnaireUpdateRecord from "./pages/QuestionnaireUpdateRecord/QuestionnaireUpdateRecord";
import QuestionnaireUploadRecords from "./pages/QuestionnaireUpdateRecord/QuestionnaireUploadRecord";
import SecondOpinionAppointment from "./pages/SecondOpinionAppointment/SecondOpinionAppointment";
import BookedAppointment from "./pages/BookedAppointment/BookedAppointment";
import UploadMoreRecords from "./pages/UploadMoreRecords/UploadMoreRecords";
import PatientRecordsRequest from "./pages/PatientRecordsRequest/PatientRecordsRequest";
import SecondOpinionAppointmentReschedule from "./pages/SecondOpinionAppointmentReschedule/SecondOpinionAppointmentReschedule";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Insurance from "./pages/Insurance/InsuranceForm";
import InsuranceInformationForm from "./pages/InsuranceInformation/InsuranceForm";

import SmartScanWorks from "./pages/SmartScanWorks/SmartScanWorks";
import SmartScanInstruction from "./pages/SmartScanInstruction/SmartScanInstruction";
import SmartScanLowerArch from "./pages/SmartScanLowerArch/SmartScanLowerArch";
import SmartScanLowerArchConfirm from "./pages/SmartScanLowerArchConfirm/SmartScanLowerArchConfirm";
import SmartScanLowerLip from "./pages/SmartScanLowerLip/SmartScanLowerLip";
import SmartScanLowerLipConfirm from "./pages/SmartScanLowerLipConfirm/SmartScanLowerLipConfirm";
import SmartScanUpperArch from "./pages/SmartScanUpperArch/SmartScanUpperArch";
import SmartScanUpperArchConfirm from "./pages/SmartScanUpperArchConfirm/SmartScanUpperArchConfirm";
import SmartScanUpperLip from "./pages/SmartScanUpperLip/SmartScanUpperLip";
import SmartScanUpperLipConfirm from "./pages/SmartScanUpperLipConfirm/SmartScanUpperLipConfirm";
import SmartScanBite from "./pages/SmartScanBite/SmartScanBite";
import SmartScanBiteConfirm from "./pages/SmartScanBiteConfirm/SmartScanBiteConfirm";
import SmartScanPose from "./pages/SmartScanPose/SmartScanPose";
import SmartScanSubmittedRecords from "./pages/SmartScanSubmittedRecords/SmartScanSubmittedRecords";
import SmartScanSelectPatient from "./pages/SmartScanSelectPatient/SmartScanSelectPatient";
import SmartScanDisclaimer from "./pages/SmartScanDisclaimer/SmartScanDisclaimer";
import SmartScanSubmitted from "./pages/SmartScanSubmitted/SmartScanSubmitted";
import WellnessScore from "./pages/WellnessScore/WellnessScore";
import WellnessScorePrint from "./pages/WellnessScorePrint/WellnessScorePrint";
import CancelPayment from "./pages/CancelPayment/CancelPayment";
import SmartScanPhotoOption from "./pages/SmartScanPhotoOption/SmartScanPhotoOption";
import Chat from "./pages/Chat/Chat.js";
import QuestionnaireInsuranceInfo from "./pages/QuestionnaireInsuranceInfo/QuestionnaireInsuranceInfo.js";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route
            path="/registration-success"
            element={<RegistrationSuccess />}
          />
          <Route
            path="/registration-consent-form"
            element={<RegistrationConsentForm />}
          />
          <Route path="/about" element={<About />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/set-password" element={<SetPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/verify-email/:code" element={<VerifyEmail />} />
          <Route
            path="/survey-form-submission"
            element={<SurveyFormSubmission />}
          />
          <Route
            path="/patient-record-request"
            element={<PatientRecordsRequest />}
          />
          <Route path="/" element={<RequireAuth />} />
          <Route path="" element={<RequireAuth />} />
          {/* protected routes */}
          <Route element={<RequireAuth />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/see-dentist" element={<SeeDentist />} />
            <Route
              path="/see-dentist-pharmacy-selection"
              element={<SeeDentistPharmacySelection />}
            />
            <Route
              path="/see-dentist-billing-info"
              element={<SeeDentistBilling />}
            />
            <Route path="/see-dentist-review" element={<SeeDentistReview />} />
            <Route
              path="/appointment-schedule"
              element={<AppointmentSchedule />}
            />
            <Route
              path="/appointment-schedule-pharmacy-selection"
              element={<AppointmentSchedulePharmacySelection />}
            />
            <Route
              path="/appointment-schedule-billing"
              element={<AppointmentScheduleBilling />}
            />
            <Route
              path="/appointment-schedule-date-time"
              element={<AppointmentScheduleDateTimePage />}
            />
            <Route
              path="/appointment-schedule-review"
              element={<AppointmentScheduleReview />}
            />

            <Route
              path="/reschedule-virtual-appointment"
              element={<AppointmentReschedule />}
            />
            <Route path="/cancel-appointment" element={<AppointmentCancel />} />

            <Route path="/prescription" element={<Prescription />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/uploads" element={<Uploads />} />
            <Route path="/forms" element={<Forms />} />
            <Route
              path="/form-print/:medicalFormPatientId/:historyId"
              element={<FormPrint />}
            />
            <Route path="/form-submission" element={<FormSubmission />} />
            <Route path="/notes" element={<Notes />} />
            <Route path="/visit-history" element={<VisitHistory />} />
            <Route path="/education" element={<Education />} />
            <Route
              path="/insurance-information"
              element={<InsuranceInformationForm />}
            />
            <Route path="/my-dependents" element={<MyDependents />} />
            <Route path="/profile-setting" element={<ProfileSetting />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/billing-information"
              element={<BillingInformation />}
            />
            <Route
              path="/prescription-print/:patientId/:prescriptionId"
              element={<PrescriptionPrint />}
            />
            <Route
              path="/notes-print/:noteId/:noteType"
              element={<NotesPrint />}
            />
            <Route
              path="/visit-history-print/:patientId/:completionDateAndTime/:consultationId"
              element={<VisitHistoryPrint />}
            />

            <Route path="/instruction" element={<Instruction />} />
            <Route path="/questionnaire" element={<Questionnaire />} />
            <Route
              path="/record-request"
              element={<QuestionnaireRecordRequest />}
            />

            <Route
              path="/questionnaire-insurance-info"
              element={<QuestionnaireInsuranceInfo />}
            />

            <Route
              path="/questionnaire-intake-form"
              element={<QuestionnaireIntakeForm />}
            />
            <Route
              path="/questionnaire-review-submit"
              element={<QuestionnaireReviewSubmit />}
            />
            <Route
              path="/questionnaire-billing-info"
              element={<QuestionnaireBillingInfo />}
            />
            <Route
              path="/QuestionnaireAfterPaymentSuccessful"
              element={<QuestionnaireAfterPaymentSuccessful />}
            />
            <Route path="/consent-form" element={<ConsentForm />} />
            <Route path="/case-review" element={<CaseReview />} />
            <Route
              path="/request-completed-details/:secondOpinionId"
              element={<RequestCompletedDetail />}
            />
            <Route
              path="/update-record"
              element={<QuestionnaireUpdateRecord />}
            />
            <Route
              path="/upload-record"
              element={<QuestionnaireUploadRecords />}
            />
            <Route
              path="/second-opinion-appointment"
              element={<SecondOpinionAppointment />}
            />
            <Route
              path="/second-opinion-appointment-reschedule"
              element={<SecondOpinionAppointmentReschedule />}
            />
            <Route path="/booked-appointment" element={<BookedAppointment />} />
            <Route
              path="/upload-more-records"
              element={<UploadMoreRecords />}
            />

            <Route path="/smart-scan-works" element={<SmartScanWorks />} />
            <Route
              path="/smart-scan-visit"
              element={<SmartScanSelectPatient />}
            />
            <Route
              path="/smart-scan-disclaimer"
              element={<SmartScanDisclaimer />}
            />
            <Route
              path="/smart-scan-photo-options"
              element={<SmartScanPhotoOption />}
            />
            <Route
              path="/smart-scan-instruction"
              element={<SmartScanInstruction />}
            />
            <Route
              path="/smart-scan-lower-arch"
              element={<SmartScanLowerArch />}
            />
            <Route
              path="/smart-scan-lower-arch-confirm"
              element={<SmartScanLowerArchConfirm />}
            />
            <Route
              path="/smart-scan-lower-lip"
              element={<SmartScanLowerLip />}
            />
            <Route
              path="/smart-scan-lower-lip-confirm"
              element={<SmartScanLowerLipConfirm />}
            />
            <Route
              path="/smart-scan-upper-arch"
              element={<SmartScanUpperArch />}
            />
            <Route
              path="/smart-scan-upper-arch-confirm"
              element={<SmartScanUpperArchConfirm />}
            />
            <Route
              path="/smart-scan-upper-lip"
              element={<SmartScanUpperLip />}
            />
            <Route
              path="/smart-scan-upper-lip-confirm"
              element={<SmartScanUpperLipConfirm />}
            />
            <Route path="/smart-scan-bite" element={<SmartScanBite />} />
            <Route
              path="/smart-scan-bite-confirm"
              element={<SmartScanBiteConfirm />}
            />
            <Route path="/smart-scan-pose" element={<SmartScanPose />} />
            <Route
              path="/smart-scan-submitted"
              element={<SmartScanSubmitted />}
            />
            <Route
              path="/smart-scan-submitted-records"
              element={<SmartScanSubmittedRecords />}
            />
            <Route
              path="/smart-scan-wellness-score"
              element={<WellnessScore />}
            />
            <Route
              path="/wellness-score-print/:smartScanId"
              element={<WellnessScorePrint />}
            />
            <Route path="/cancel-payment" element={<CancelPayment />} />

            <Route path="/chat" element={<Chat />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
