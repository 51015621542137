import { onBeforeSend } from "./authManager";
import { DentalDotComApiAccessKey } from "../constants";
import $ from "jquery";

export const getJson = (url, success_callback, error_callback, authNeeded) => {
  if (typeof authNeeded === "undefined") {
    authNeeded = true;
  }

  $.ajax({
    url: url,
    type: "GET",
    cache: false,
    headers: {
      apiKey: DentalDotComApiAccessKey
    },
    contentType: "application/json",
    beforeSend: authNeeded ? onBeforeSend : "",
    success: function (data) {
      success_callback(data);
    },
    error: function (err) {
      error_callback(err);
    }
  });
};

export const postFormData = (
  url,
  method,
  formData,
  success_callback,
  error_callback
) => {
  $.ajax({
    url: url,
    type: method,
    data: formData,
    cache: false,
    headers: {
      apiKey: DentalDotComApiAccessKey
    },
    contentType: false,
    processData: false,
    dataType: "json",
    beforeSend: onBeforeSend,
    success: function (data) {
      success_callback(data);
    },
    error: function (err) {
      error_callback(err);
    }
  });
};

export const postJsonData = (
  url,
  method,
  jsonData,
  success_callback,
  error_callback
) => {
  $.ajax({
    url: url,
    type: method,
    data: jsonData,
    cache: false,
    headers: {
      apiKey: DentalDotComApiAccessKey
    },
    contentType: "application/json",
    processData: false,
    beforeSend: onBeforeSend,
    success: function (data) {
      success_callback(data);
    },
    error: function (err) {
      error_callback(err);
    }
  });
};
