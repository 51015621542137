const Functionalities = {
  TokenDataKey: "dental_com_token_key",
  SelectedPatientProfileKey: "selected_patient_profile_key",
  SelectedPatientId: "selected_patient_id_key",
  ShouldHideNavbar: "should_hide_navbar_key",
  ShouldHideChatBox: "should_hide_chat_box"
};

const SignUpFormFields = {
  FirstName: "firstName",
  MiddleName: "middleName",
  LastName: "lastName",
  DateOfBirth: "dateOfBirth",
  Gender: "gender",
  Phone: "phone",
  Email: "email",
  Address1: "address1",
  Address2: "address2",
  Country: "country",
  State: "state",
  City: "city",
  Zip: "zip",
  Timezone: "timezone",
  Password: "password",
  ConfirmPassword: "confirmPassword",
  Consent: "consent",
  Relation: "relation",
  Insurance: "insurance",
  DenticonPatientId: "denticonPatientId",
  EmployerId: "employerId",
  IsSubscribedForSms: "isSubscribedForSms"
};

const InsuranceFormFields = {
  PatientRelation: "relationToPatient",
  FirstName: "insuredFirstName",
  LastName: "insuredLastName",
  Gender: "gender",
  DateOfBirth: "insuredDob",
  InsuranceCarrier: "carrierCode",
  InsuranceCarrierName: "carrierName",
  SubscriberID: "insuredId",
  Employer: "employerName",
  Address: "insuredAddress",
  City: "insuredCity",
  State: "state",
  Zip: "zip",
  Country: "country",
  ApplyToAllDependents: "isApplicableToDependent",
  ProductType: "productTypeId",
  GroupNo: "groupNo",
  GroupName: "groupName",
  SSN: "ssn",
  SubscriberIdType: "subscriberIdType"
};

const AddMedicalRecordFormFields = {
  RecordFor: "recordFor",
  Name: "name",
  Type: "type",
  Note: "note",
  Date: "date"
};

const SecondOpinionConsentFormFields = {
  Requestor: "requestor",
  PatientName: "patientName",
  DateOfBirth: "dateOfBirth",
  ElectronicFormat: "electronicFormat",
  ElectronicHIPAA: "electronicHIPAA",
  DentistOrPracticeName: "dentistOrPracticeName",
  Relation: "relation",
  SignatureDataURL: "signatureDataURL",
  SignatureDate: "signatureDate",
  RecordFor: "recordFor"
};

const RequestorPatientRelations = {
  Parent: "Parent",
  Conservator: "Conservator"
};

const RecordForOptions = {
  Myself: "Myself",
  Other: "Other"
};

const ForgotPasswordFormFields = {
  MobileNumberOrEmailId: "mobileNumberOrEmailId"
};

const Roles = {
  Patient: 2
};

const Patterns = {
  Email: /^((\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*)\s*[,]{0,1}\s*)+$/,
  Password: /^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/
};

const DateTimeFormats = {
  MMM_DD_YYYY: "MMM DD, YYYY",
  DD_MMM_YYYY: "DD MMM, YYYY",
  HH_MM_A: "hh:mm A",
  YYYY_MM_DD: "YYYY-MM-DD",
  HH_MM_SS: "h:mm:ss",
  MM_DD_YY: "MM/DD/YY",
  MM_DD_YYYY: "MM/DD/YYYY",
  YYYY_MM_DD_hh_mm_A: "YYYY-MM-DD hh:mm A"
};

const Pagination = {
  ItemPerPage: 5,
  UpcomingAppointmentsItemPerPage: 2,
  SecondOpinionsItemPerPage: 2,
  FirstPageNo: 1,
  SmartScansItemPerPage: 2,
  AIChatItemPerPage: 10
};

const MedicalRecordTypes = {
  Chart: 1,
  Other: 2,
  Telscope: 3
};

const NoteType = {
  ProgressNote: 1,
  PatientNote: 2
};

const ReferInfoStatusTypes = {
  None: 0,
  Accepted: 1,
  Declined: 2
};

const ReferralSuggestingCompletionType = {
  AvailableProvider: 1,
  InviteProvider: 2
};

const DistanceOptions = {
  Five: 5,
  Ten: 10,
  Fifty: 50,
  None: 0
};

const GenderOptions = {
  Male: "M",
  Female: "F",
  Unknown: "U",
  Other: "O"
};

const SmsSubscriptionOptions = {
  Yes: 1,
  No: 0
};

const ReferToProviderTypes = {
  Network: 1,
  Email: 2,
  Insurance: 3,
  Recommendation: 4,
  None: 5
};

const IntervalTimes = {
  Minute_MS_1: 1000,
  Minute_MS_5: 5000,
  Minute_MS_30: 30000,
  MS_25: 25
};

const appointmentTypes = {
  clinicVisit: 2,
  videoConferencing: 3,
  campaingPackage: 4,
  package: 5,
  block: 6,
  scheduledOnDemandConsultation: 7
};

const UploadRecordNames = {
  Images: "Images",
  Documents: "Documents"
};

const SortOrders = {
  None: 0,
  Ascending: 1,
  Descending: 2
};

const ConsultationStatus = {
  Waiting: 1,
  Started: 2,
  Ended: 3,
  Scheduled: 4,
  Completed: 5,
  Cancelled: 6,
  CancelledByCSR: 7,
  FollowUp: 8,
  Initiated: 10
};

const MaxAllowdFileSizes = {
  FILE_MAX_SIZE_IN_MB: 250,
  IMAGE_MAX_SIZE_IN_MB: 1,
  IMAGE_MAX_SIZE_IN_MB_5: 5
};

const DurationsInMS = {
  Hour: 60 * 60 * 1000
};

const PATIENT_PORTAL_AUTO_LOGIN_API_KEY =
  "1be06711-a658-4d61-9cc0-aff4151d2562";

const CountryCodes = {
  US: "US"
};

const IS_SEEN = 1;

const NotificationTypes = {
  VIDEO_STARTED: "VIDEOSTARTED",
  SMARTSCAN_CASEREPORT_RESOLVED: "SMARTSCANCASEREPORTRESOLVED",
  NEW_PRESCRIPTION_ADDED: "NEWPRESCRIPTIONADDED",
  NEW_PRESCRIPTION_UPLOADED: "NEWPRESCRIPTIONUPLOADED",
  NEW_REPORT_UPLOADED: "NEWREPORTUPLOADED",
  PRESCRIPTION_UPDATED: "NEWPRESCRIPTIONUPDATED",
  NEW_EDUCATION_ADDED: "NEWEDUCATIONADDED",
  EDUCATION_REMOVED: "EDUCATIONREMOVED",
  PROGRESS_NOTE_SHARED: "PROGRESSNOTESHARED",
  REFERRED_TOPATIENT: "REFERREDTOPATIENT",
  REFERRED_PATIENT_TOPROVIDER: "REFERREDPATIENTTOPROVIDER",
  NEW_RECORD_ADDED: "NEWRECORDADDED",
  NEW_RECORD_ADDED_FOR_FAMILY_MEMBER: "NEWRECORDADDEDFORFAMILYMEMBER",
  NEW_MEDICAL_FORM_ASSIGNED: "NEWMEDICALFORMASSIGNED",
  SUBMITTED_FORM: "SUBMITTED_FORM",
  NEW_VIDEO_SHARED: "NEWVIDEOSHARED",
  REFERRAL_DELETED_TOPATIENT: "REFERDELETEDTOPATIENT",
  REFERRAL_DELETED_TOPROVIDER: "REFERDELETEDTOPROVIDER",
  REFERRAL_SUGGESTION_ADDED: "REFERRALSUGGESTIONADDED",
  CASEREPORT_RESOLVED: "CASEREPORTRESOLVED",
  REQUESTING_SECONDOPINIONADDITIONALINFO_FOR_PATIENT:
    "REQUESTINGSECONDOPINIONADDITIONALINFO"
};
const ReferralsNotificationTypesRedirectURL = [
  [NotificationTypes.REFERRED_TOPATIENT, "/referrals"],
  [NotificationTypes.REFERRED_PATIENT_TOPROVIDER, "/referrals"],
  [NotificationTypes.REFERRAL_DELETED_TOPATIENT, "/referrals"],
  [NotificationTypes.REFERRAL_DELETED_TOPROVIDER, "/referrals"],
  [NotificationTypes.REFERRAL_SUGGESTION_ADDED, "/referrals"]
];

const NotificationTypesToRedirectUrl = new Map([
  ...ReferralsNotificationTypesRedirectURL,
  [NotificationTypes.NEW_PRESCRIPTION_ADDED, "/prescription"],
  [NotificationTypes.NEW_PRESCRIPTION_UPLOADED, "/prescription"],
  [NotificationTypes.PRESCRIPTION_UPDATED, "/prescription"],
  [NotificationTypes.NEW_EDUCATION_ADDED, "/education"],
  [NotificationTypes.EDUCATION_REMOVED, "/education"],
  [NotificationTypes.NEW_REPORT_UPLOADED, "/uploads"],
  [NotificationTypes.PROGRESS_NOTE_SHARED, "/notes"],
  [NotificationTypes.NEW_RECORD_ADDED, "/uploads"],
  [NotificationTypes.NEW_RECORD_ADDED_FOR_FAMILY_MEMBER, "/uploads"],
  [NotificationTypes.NEW_MEDICAL_FORM_ASSIGNED, "/forms"],
  [NotificationTypes.SUBMITTED_FORM, "/forms"],
  [NotificationTypes.NEW_VIDEO_SHARED, "/uploads"]
]);

const SELF_SUBSCRIBER_RELATION_CODE = 18;

const KeyCodes = {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  SHIFT: 16,
  CTRL: 17,
  ALT: 18,
  PAUSE: 19,
  CAPS_LOCK: 20,
  ESCAPE: 27,
  SPACE: 32,
  PAGE_UP: 33,
  PAGE_DOWN: 34,
  END: 35,
  HOME: 36,
  LEFT_ARROW: 37,
  UP_ARROW: 38,
  RIGHT_ARROW: 39,
  DOWN_ARROW: 40,
  INSERT: 45,
  DELETE: 46,
  KEY_0: 48,
  KEY_1: 49,
  KEY_2: 50,
  KEY_3: 51,
  KEY_4: 52,
  KEY_5: 53,
  KEY_6: 54,
  KEY_7: 55,
  KEY_8: 56,
  KEY_9: 57,
  KEY_A: 65,
  KEY_B: 66,
  KEY_C: 67,
  KEY_D: 68,
  KEY_E: 69,
  KEY_F: 70,
  KEY_G: 71,
  KEY_H: 72,
  KEY_I: 73,
  KEY_J: 74,
  KEY_K: 75,
  KEY_L: 76,
  KEY_M: 77,
  KEY_N: 78,
  KEY_O: 79,
  KEY_P: 80,
  KEY_Q: 81,
  KEY_R: 82,
  KEY_S: 83,
  KEY_T: 84,
  KEY_U: 85,
  KEY_V: 86,
  KEY_W: 87,
  KEY_X: 88,
  KEY_Y: 89,
  KEY_Z: 90,
  LEFT_META: 91,
  RIGHT_META: 92,
  SELECT: 93,
  NUMPAD_0: 96,
  NUMPAD_1: 97,
  NUMPAD_2: 98,
  NUMPAD_3: 99,
  NUMPAD_4: 100,
  NUMPAD_5: 101,
  NUMPAD_6: 102,
  NUMPAD_7: 103,
  NUMPAD_8: 104,
  NUMPAD_9: 105,
  MULTIPLY: 106,
  ADD: 107,
  SUBTRACT: 109,
  DECIMAL: 110,
  DIVIDE: 111,
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F5: 116,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  F12: 123,
  NUM_LOCK: 144,
  SCROLL_LOCK: 145,
  SEMICOLON: 186,
  EQUALS: 187,
  COMMA: 188,
  DASH: 189,
  PERIOD: 190,
  FORWARD_SLASH: 191,
  GRAVE_ACCENT: 192,
  OPEN_BRACKET: 219,
  BACK_SLASH: 220,
  CLOSE_BRACKET: 221,
  SINGLE_QUOTE: 222
};

const SecondOpinionFormTypes = {
  IntakeForm: 1,
  ConsentForm: 2
};

const SecondOpinionStatus = {
  Pending: 1,
  RecordRequested: 2,
  RecordSubmitted: 3,
  ReportReady: 4,
  CaseAccepted: 5,
  AdditionalInfoNeeded: 6
};

const CONSULTATION_PROVIDER_PENDING = -1;

const AppointmentStatus = {
  Requested: 1,
  Accepted: 2,
  Rejected: 3,
  Cancelled: 4,
  Completed: 5,
  DateTimeChanged: 6,
  Started: 7,
  Created: 8,
  Missed: 9,
  Blocked: 10
};

const AddDependentSuccessMessage = "Dependent profile has been added";

const ConsultationEventName = {
  PATIENTCONSULTATIONINITIATED: "PATIENTCONSULTATIONINITIATED"
};

const PageName = {
  SEEDENTISTREVIEWANDSUBMIT: "SEEDENTISTREVIEWANDSUBMIT"
};

const MobileDevices = {
  None: 1,
  Android: 2,
  iOS: 3
};

const SmartScanStatus = {
  Submitted: 1,
  NotifiedToothlens: 2,
  ReadyForReview: 3,
  CaseAccepted: 4,
  CaseReviewed: 5,
  ReportReady: 6,
  CaseRejected: 11
};

const SmartScanGeneralStatus = {
  Submitted: 1,
  InProgress: 2,
  ReportReady: 3
};

const smartScanCategory = {
  Bite: 1,
  UpperArch: 2,
  LowerArch: 3,
  UpperLip: 4,
  LowerLip: 5,
  MouthClosed: 6
};

const SmartScanOralHealthStatus = {
  Good: "Good",
  NeedsImprovement: "Needs Improvement",
  NonObservable: "Non Observable",
  Poor: "Poor"
};

const SmartScanGraphDataPropertyIndices = {
  CreatedOn: 0,
  Score: 1,
  SmartScanId: 2,
  PatientId: 3
};

const FileCompressQuality = {
  Image_Quality: 0.6
};

const SmartScanTotalPhotos = 5;

const SmartScanPerfectScore = 100;

const CampaignStateSettingTypes = {
  None: 0,
  Assigned: 1,
  Shared: 2,
  Declined: 3
};

const PaymentCancelUrl = "/cancel-payment";

const IndexedDBName = "Dental.com";

const SmartScanOverAllStatus = {
  Good: "Good",
  Poor: "Poor",
  Moderate: "Moderate"
};

const SignatureType = {
  EType: 1,
  Scribe: 2
};

const InsuranceCaptureOptionValues = {
  Yes: "insurance-yes",
  No: "insurance-no"
};

const ConsentOptions = {
  None: 0,
  Agree: 1,
  Disagree: 2
};

const CampaignTypes = {
  Appointment: 1,
  OnDemandCall: 2
};

const TokenType = {
  None: 0,
  PasswordResetToken: 1
};

const GroupNoDefaultValue = "0000";
const GroupNameMaxLength = 35;
const GroupNumMaxLength = 30;
const SupportedAPIVersionForAutoLogin = [1];
const CameraAttribute = "capture";
const CameraAttributeValues = {
  User: "user",
  Environment: "environment"
};
const DentalDotComPortalType = 2;

const RegistrationTypes = {
  Default: 1,
  SkipPassword: 2
};

const PrimaryConsultantInfoFormField = {
  FirstName: "FirstName",
  LastName: "LastName",
  Phone: "Phone",
  Email: "Email"
};

const ValidationTypes = {
  DateOfBirth: "dateOfBirth",
  ScheduleAppointment: "scheduleAppointment"
};

const SmartScanCaptureOptions = {
  Myself: 1,
  Assisted: 2
};

const DiscountTypes = {
  PercentageDiscount: 1,
  FixedAmountDiscount: 2
};

const SubscriberIdTypes = {
  DEFAULT: 0,
  SSN: 1,
  EEID: 2
};

const ContractedTypes = {
  None: 0,
  Par: 1
};

const MedicalFormTypes = {
  SmartScan: 1
};

const DentalDotComUrlParamLinkType = {
  DentistDirectReferralUrl: 1
};

const SecondOpinionCaseReportTypes = {
  TreatmentRecommendation: 1,
  Observation: 2
};

const DentalDotComApiAccessKey = "b14b69c1-759f-486d-8054-7b85d147ee1c";
const DentalDotComPageUrl = "https://dental.com/";

const GoogleMapsApiKey = "AIzaSyD3OB98TXmLXAU4wtUCq5SbLmk9fGWRrkc";
const GooglePlaceAPIOptions = {
  componentRestrictions: { country: ["US"]},
  fields: ["address_components", "formatted_address", "place_id", "geometry", "icon", "name"],
  strictBounds: false
}

const SignupPageMetaDescription = "Sign up for a free Dental.com account where you can scan your teeth at-home to detect issues like cavities and gum inflammation. Speak to an online dentist 24/7."

const SignInPageMetaDescription = "Sign in to your Dental.com account to access 24/7 online dentists, AI-powered photo assessments and more."

const EligibilityStatus = {
  Yes: "YES",
  No: "NO"
};

const SmartScanToothStatus = {
  Observe: 1,
  NeedsTreatment: 2
};

const SmartScanQuadrantStatus = {
  Slight: 1,
  Moderate: 2,
  Severe: 3
};

const SmartScanQuadrantType = {
  UpperRight: 1,
  UpperLeft: 2,
  LowerRight: 3,
  LowerLeft: 4
};

const LinkTypes = {
  SeeDentistNow: 1
};

const CICILinks = {
  "[link]": LinkTypes.SeeDentistNow
};

const UtmParams = {
  Source: "utm_source",
  Medium: "utm_medium",
  Campaign: "utm_campaign",
  Term: "utm_term",
  Content: "utm_content"
};

module.exports = {
  MedicalRecordTypes: MedicalRecordTypes,
  ReferInfoStatusTypes: ReferInfoStatusTypes,
  AddMedicalRecordFormFields: AddMedicalRecordFormFields,
  Pagination: Pagination,
  DateTimeFormats: DateTimeFormats,
  Roles: Roles,
  Patterns: Patterns,
  ForgotPasswordFormFields: ForgotPasswordFormFields,
  Functionalities: Functionalities,
  SignUpFormFields: SignUpFormFields,
  InsuranceFormFields: InsuranceFormFields,
  RegistrationTypes: RegistrationTypes,
  NoteType: NoteType,
  ReferralSuggestingCompletionType: ReferralSuggestingCompletionType,
  DistanceOptions: DistanceOptions,
  GenderOptions: GenderOptions,
  SmsSubscriptionOptions: SmsSubscriptionOptions,
  ReferToProviderTypes: ReferToProviderTypes,
  IntervalTimes: IntervalTimes,
  appointmentTypes: appointmentTypes,
  UploadRecordNames: UploadRecordNames,
  SortOrders: SortOrders,
  ConsultationStatus: ConsultationStatus,
  MaxAllowdFileSizes: MaxAllowdFileSizes,
  DurationsInMS: DurationsInMS,
  PATIENT_PORTAL_AUTO_LOGIN_API_KEY: PATIENT_PORTAL_AUTO_LOGIN_API_KEY,
  CountryCodes: CountryCodes,
  IS_SEEN: IS_SEEN,
  NotificationTypes: NotificationTypes,
  KeyCodes: KeyCodes,
  SecondOpinionFormTypes: SecondOpinionFormTypes,
  SecondOpinionStatus: SecondOpinionStatus,
  CONSULTATION_PROVIDER_PENDING: CONSULTATION_PROVIDER_PENDING,
  SecondOpinionConsentFormFields: SecondOpinionConsentFormFields,
  RequestorPatientRelations: RequestorPatientRelations,
  RecordForOptions: RecordForOptions,
  AppointmentStatus: AppointmentStatus,
  ConsultationEventName,
  PageName,
  MobileDevices: MobileDevices,
  SmartScanStatus: SmartScanStatus,
  smartScanCategory: smartScanCategory,
  SmartScanGeneralStatus: SmartScanGeneralStatus,
  SmartScanOralHealthStatus: SmartScanOralHealthStatus,
  SmartScanGraphDataPropertyIndices: SmartScanGraphDataPropertyIndices,
  FileCompressQuality: FileCompressQuality,
  SmartScanTotalPhotos: SmartScanTotalPhotos,
  SmartScanPerfectScore: SmartScanPerfectScore,
  CampaignStateSettingTypes: CampaignStateSettingTypes,
  SELF_SUBSCRIBER_RELATION_CODE: SELF_SUBSCRIBER_RELATION_CODE,
  PaymentCancelUrl: PaymentCancelUrl,
  IndexedDBName: IndexedDBName,
  NotificationTypesToRedirectUrl: NotificationTypesToRedirectUrl,
  SmartScanOverAllStatus: SmartScanOverAllStatus,
  SignatureType: SignatureType,
  InsuranceCaptureOptionValues: InsuranceCaptureOptionValues,
  GroupNoDefaultValue: GroupNoDefaultValue,
  GroupNameMaxLength: GroupNameMaxLength,
  GroupNumMaxLength: GroupNumMaxLength,
  SupportedAPIVersionForAutoLogin,
  CameraAttribute: CameraAttribute,
  CameraAttributeValues: CameraAttributeValues,
  DentalDotComPortalType: DentalDotComPortalType,
  ConsentOptions: ConsentOptions,
  CampaignTypes: CampaignTypes,
  PrimaryConsultantInfoFormField: PrimaryConsultantInfoFormField,
  TokenType: TokenType,
  ValidationTypes: ValidationTypes,
  SmartScanCaptureOptions: SmartScanCaptureOptions,
  DiscountTypes: DiscountTypes,
  SubscriberIdTypes: SubscriberIdTypes,
  AddDependentSuccessMessage: AddDependentSuccessMessage,
  ContractedTypes: ContractedTypes,
  ReferralsNotificationTypesRedirectURL: ReferralsNotificationTypesRedirectURL,
  MedicalFormTypes: MedicalFormTypes,
  DentalDotComUrlParamLinkType: DentalDotComUrlParamLinkType,
  DentalDotComApiAccessKey: DentalDotComApiAccessKey,
  DentalDotComPageUrl: DentalDotComPageUrl,
  EligibilityStatus: EligibilityStatus,
  SecondOpinionCaseReportTypes: SecondOpinionCaseReportTypes,
  SmartScanToothStatus: SmartScanToothStatus,
  SmartScanQuadrantStatus: SmartScanQuadrantStatus,
  SmartScanQuadrantType: SmartScanQuadrantType,
  GooglePlaceAPIOptions: GooglePlaceAPIOptions,
  SignInPageMetaDescription: SignInPageMetaDescription,
  SignupPageMetaDescription: SignupPageMetaDescription,
  GoogleMapsApiKey: GoogleMapsApiKey,
  LinkTypes: LinkTypes,
  CICILinks: CICILinks,
  UtmParams: UtmParams
};
