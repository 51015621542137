import { SmartScanQuadrantStatus, SmartScanToothStatus } from "../constants";
import { getSmartScanQuadrantTypeName } from "./utils";

function getToothConditionData(condition) {
  let { annotations } = condition;

  let observeToothCount = 0,
    needsTreatmentToothCount = 0;
  let observeToothNumbers = [],
    needsTreatmentToothNumbers = [];

  annotations.forEach((a) => {
    a?.annotationDetails.forEach((ad) => {
      if (ad.status == SmartScanToothStatus.NeedsTreatment) {
        needsTreatmentToothCount += 1;
        needsTreatmentToothNumbers.push(ad.toothNumber);
      } else if (ad.status == SmartScanToothStatus.Observe) {
        observeToothCount += 1;
        observeToothNumbers.push(ad.toothNumber);
      }
    });
  });

  return {
    observeToothCount,
    observeToothNumbers,
    needsTreatmentToothCount,
    needsTreatmentToothNumbers
  };
}

function getQuadrantData(condition) {
  let { annotations } = condition;

  let severeCount = 0,
    slightCount = 0,
    moderateCount = 0;
  let severeQuadrantNumbers = [],
    slightQuadrantNumbers = [],
    moderateQuadrantNumbers = [];

  annotations.forEach((a) => {
    a?.annotationDetails.forEach((ad) => {
      let quadrantNumber = parseInt(ad.quadrantNumber);
      let quadrantName = getSmartScanQuadrantTypeName(quadrantNumber);

      if (ad.status == SmartScanQuadrantStatus.Slight) {
        slightCount += 1;

        slightQuadrantNumbers.push(quadrantName);
      } else if (ad.status == SmartScanQuadrantStatus.Moderate) {
        moderateCount += 1;

        moderateQuadrantNumbers.push(quadrantName);
      } else if (ad.status == SmartScanQuadrantStatus.Severe) {
        severeCount += 1;

        severeQuadrantNumbers.push(quadrantName);
      }
    });
  });

  return {
    severeCount,
    severeQuadrantNumbers,
    slightCount,
    slightQuadrantNumbers,
    moderateCount,
    moderateQuadrantNumbers
  };
}

export function getSmartScanInitialMessageForCICI(smartScanCaseReport) {
  if (smartScanCaseReport) {
    let {
      cavity,
      brokenTooth,
      defectiveRestoration,
      plaqueBuildUp,
      gumInflammation,
      overAllCondition,
      patient
    } = smartScanCaseReport;

    let msg = "";

    msg += patient?.firstName + "_" + overAllCondition?.score;

    if (cavity) {
      let {
        observeToothCount,
        observeToothNumbers,
        needsTreatmentToothCount,
        needsTreatmentToothNumbers
      } = getToothConditionData(cavity);

      let { status } = cavity;
      msg += `_${status} (${observeToothCount} observe`;
      if (observeToothCount > 0) {
        msg += ` at ${observeToothNumbers.join(" and ")}`;
      }

      msg += ` ${needsTreatmentToothCount} cavity`;

      if (needsTreatmentToothCount > 0) {
        msg += ` at ${needsTreatmentToothNumbers.join(" and ")}`;
      }

      msg += `)`;
    }

    if (brokenTooth) {
      let {
        observeToothCount,
        observeToothNumbers,
        needsTreatmentToothCount,
        needsTreatmentToothNumbers
      } = getToothConditionData(brokenTooth);

      let { status } = brokenTooth;
      msg += `_${status} (${observeToothCount} broken tooth observe`;
      if (observeToothCount > 0) {
        msg += ` at ${observeToothNumbers.join(" and ")}`;
      }

      msg += ` ${needsTreatmentToothCount} broken tooth`;

      if (needsTreatmentToothCount > 0) {
        msg += ` at ${needsTreatmentToothNumbers.join(" and ")}`;
      }

      msg += `)`;
    }

    if (defectiveRestoration) {
      let { status } = defectiveRestoration;

      msg += `_${status}`;
    }

    if (plaqueBuildUp) {
      let {
        severeCount,
        severeQuadrantNumbers,
        slightCount,
        slightQuadrantNumbers,
        moderateCount,
        moderateQuadrantNumbers
      } = getQuadrantData(plaqueBuildUp);

      let { status } = plaqueBuildUp;

      msg += `_${status} (${moderateCount} moderate`;

      if (moderateCount > 0) {
        msg += ` at ${moderateQuadrantNumbers.join(" and ")}`;
      }

      msg += ` ${severeCount} severe`;

      if (severeCount > 0) {
        msg += ` at ${severeQuadrantNumbers.join(" and ")}`;
      }

      msg += ` ${slightCount} slight`;

      if (slightCount > 0) {
        msg += ` at ${slightQuadrantNumbers.join(" and ")}`;
      }

      msg += `)`;
    }

    if (gumInflammation) {
      let {
        severeCount,
        severeQuadrantNumbers,
        slightCount,
        slightQuadrantNumbers,
        moderateCount,
        moderateQuadrantNumbers
      } = getQuadrantData(gumInflammation);

      let { status } = gumInflammation;

      msg += `_${status} (${moderateCount} moderate`;

      if (moderateCount > 0) {
        msg += ` at ${moderateQuadrantNumbers.join(" and ")}`;
      }

      msg += ` ${severeCount} severe`;

      if (severeCount > 0) {
        msg += ` at ${severeQuadrantNumbers.join(" and ")}`;
      }

      msg += ` ${slightCount} slight`;

      if (slightCount > 0) {
        msg += ` at ${slightQuadrantNumbers.join(" and ")}`;
      }

      msg += `)`;
    }

    return msg;
  }
}
