import React from "react";

const ReceivedMessage = ({ message, userImg }) => {
  return (
    <>
      <div className="chatbox">
        <img src={userImg} className="author-img rounded-circle" />
        <div
          className="chat-msg msg-output"
          style={{ display: "table" }}
          dangerouslySetInnerHTML={{
            __html: message
          }}
        ></div>
        <br />
      </div>
    </>
  );
};

export default ReceivedMessage;
