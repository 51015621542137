import { getCICIApiUrl } from "../ApplicationSettings";
import { postJsonDataToCICI } from "./httpReqExternal";

export const sendMessageToCICI_Ajax = (
  jsonData,
  success_callback,
  error_callback
) => {
  let url = getCICIApiUrl();
  postJsonDataToCICI(url, "POST", jsonData, success_callback, error_callback);
};
